import { Spacing } from '@wearemojo/ui-constants';
import { createContext } from 'react';

import { ContentVariables } from '../utils/types';

export type CMSContextType = {
	spacing: Spacing;
	pauseAudioOnUnmount: boolean;
	contentVariables: ContentVariables | undefined;
};

const CMSContext = createContext<CMSContextType>({
	/* Skip defaults with cast, type still validated on Provider value prop */
} as CMSContextType);

export default CMSContext;
