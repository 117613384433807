import ArrowRightIcon from '@wearemojo/icons/Outline/Arrows/ArrowRightIcon';
import { BrandColor } from '@wearemojo/ui-constants';
import React, { ElementRef, forwardRef, useEffect, useState } from 'react';
import { ScrollView, useWindowDimensions, View } from 'react-native';
import { EdgeInsets } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BottomSheet from './BottomSheet';
import Button from './Button';
import ContainedAction from './ContainedAction';
import OptionsButton from './OptionsButton';
import Stack from './Stack';
import Text from './Text';
import { PLAYBACK_RATES, PlaybackRateType } from './utils/audio';

type Props = {
	ref: React.RefObject<ElementRef<typeof BottomSheet>>;
	setPlaybackRate: (rate: PlaybackRateType) => void;
	playbackRate: PlaybackRateType;
	safeAreaInsets?: EdgeInsets;
};

const PlaybackSpeedOptionsSheet = forwardRef<
	ElementRef<typeof BottomSheet>,
	Props
>(({ setPlaybackRate, playbackRate, safeAreaInsets }, ref) => {
	const {
		styles,
		theme: { spacing },
	} = useStyles(stylesheet);
	const [scrollContentHeight, setScrollContentHeight] = useState(0);
	const [selectedRate, setSelectedRate] = useState(playbackRate);

	useEffect(() => {
		setSelectedRate(playbackRate);
	}, [playbackRate]);

	const { height: WINDOW_HEIGHT } = useWindowDimensions();
	const SCROLL_MAX_HEIGHT = WINDOW_HEIGHT * 0.6;
	const scrollEnabled = scrollContentHeight > SCROLL_MAX_HEIGHT;

	const content = (
		<Stack direction="column" spacing={spacing.xs}>
			{PLAYBACK_RATES.map((rate) => (
				<OptionsButton
					key={rate}
					title={`${rate}x`}
					onPress={() => setSelectedRate(rate)}
					selected={rate === selectedRate}
				/>
			))}
		</Stack>
	);

	const onDismiss = (shouldResetRateSelector=true) => {
		if (shouldResetRateSelector) {
			// setTimeout to hide the jump of a playback rate when dismissing
			setTimeout(()=>{setSelectedRate(playbackRate)},500);
		}
		(
			ref as React.RefObject<ElementRef<typeof BottomSheet>>
		)?.current?.dismiss();
	};

	const handleSavePreferences = () => {
		setPlaybackRate(selectedRate);
		onDismiss(false);
	};

	return (
		<BottomSheet ref={ref} onDismiss={onDismiss}>
			<View style={styles.sheetContainer}>
				{onDismiss && (
					<ContainedAction type="close" onPress={onDismiss} position="end" />
				)}
				<View
					style={{
						paddingBottom: safeAreaInsets?.bottom,
						paddingTop: spacing.md,
					}}
				>
					<Stack spacing={spacing.xl3}>
						<Text variant="heading_xs">Playback speed</Text>
						<ScrollView
							style={{ maxHeight: SCROLL_MAX_HEIGHT }}
							onContentSizeChange={(_, contentHeight) =>
								setScrollContentHeight(contentHeight)
							}
							scrollEnabled={scrollEnabled}
						>
							{content}
						</ScrollView>
						<View style={styles.buttonContainer}>
							<Button
								title={'Save preferences'}
								onPress={handleSavePreferences}
								size="fixed"
								type="primary"
								icon={ArrowRightIcon}
								iconPosition="right"
							/>
						</View>
					</Stack>
				</View>
			</View>
		</BottomSheet>
	);
});

const stylesheet = createStyleSheet(({ spacing, radius }) => ({
	sheetContainer: {
		borderTopRightRadius: radius.md,
		borderTopLeftRadius: radius.md,
		padding: spacing.xl,
		backgroundColor: BrandColor.neutral_700,
	},
	buttonContainer: {
		alignItems: 'center',
		paddingBottom: spacing.xl2,
	},
}));

export default PlaybackSpeedOptionsSheet;
