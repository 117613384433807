import { useState } from 'react';
import {
	Pressable,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	View,
} from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from '../BackgroundView';
import Button from '../Button';
import Flair from '../Flair';
import HelpButton from '../HelpButton';
import useUIContext from '../hooks/useUIContext';
import ModalHeader from '../ModalHeader';
import Stack from '../Stack';
import Text from '../Text';
import { WhoopsError } from '../utils/types';
import {
	errorIcons,
	getErrorFlairColor,
	getErrorIconColor,
} from '../utils/whoops';
type Props = WhoopsError & {
	initialShowFormattedError?: boolean;
	onPressDismiss?: () => void;
	onPressSupport?: () => void;
};

const WhoopsView = ({
	title,
	messages,
	actions,
	error,
	type,
	initialShowFormattedError,
	onPressDismiss,
	onPressSupport,
}: Props) => {
	const {
		styles,
		theme: { spacing },
	} = useStyles(stylesheet);
	const { isDesktop, theme } = useUIContext();
	const errorFormatted = JSON.stringify(error, undefined, 2);

	const [showFormattedError, setShowFormattedError] = useState(
		initialShowFormattedError ?? false,
	);

	const flairIconColor = getErrorIconColor(type, theme);
	const flairColor = getErrorFlairColor(type, theme);
	const Icon = errorIcons[type];

	const showModalHeader = !!onPressDismiss || !!onPressSupport;

	return (
		<SafeAreaView style={styles.safeAreaView}>
			<BackgroundView background="background_primary" style={styles.bg} />
			<View style={styles.root}>
				{showModalHeader && (
					<ModalHeader
						onPressClose={onPressDismiss}
						supportButton={
							!!onPressSupport && <HelpButton onPress={onPressSupport} />
						}
					/>
				)}
				<ScrollView>
					<View
						style={[styles.container, isDesktop && styles.containerDesktop]}
					>
						<Stack spacing={spacing.md}>
							<Pressable onPress={() => setShowFormattedError((prev) => !prev)}>
								<View style={styles.flairContainer}>
									<Flair flairColor={flairColor} size="small" animated={false}>
										<View style={styles.flairIcon}>
											<Icon color={flairIconColor} size={60} strokeWidth={2} />
										</View>
									</Flair>
								</View>
							</Pressable>

							<Text.CMSContent
								variant="heading_sm"
								value={title.content}
								contentVariables={title.contentVariables}
							/>

							{messages.map(({ content, contentVariables }, idx) => (
								<Text.CMSContent
									key={idx}
									value={content}
									contentVariables={contentVariables}
								/>
							))}

							<Stack spacing={spacing.xs}>
								{actions.map(
									({ title: actionTitle, linkProvider: LinkProvider }, idx) => (
										<LinkProvider key={idx}>
											<Button
												title={actionTitle}
												type={idx === 0 ? 'primary' : 'secondary'}
											/>
										</LinkProvider>
									),
								)}
							</Stack>

							{showFormattedError && (
								<Text selectable style={styles.errorText}>
									{errorFormatted}
								</Text>
							)}
						</Stack>
					</View>
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};

const stylesheet = createStyleSheet(({ spacing, colors }) => ({
	safeAreaView: {
		flex: 1,
	},
	bg: {
		...StyleSheet.absoluteFillObject,
	},
	root: {
		flex: 1,
	},
	container: {
		paddingTop: spacing.xl3,
		paddingHorizontal: spacing.md,
	},
	containerDesktop: {
		maxWidth: 400,
		alignSelf: 'center',
	},
	flairContainer: {
		alignItems: 'flex-start',
	},
	flairIcon: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	errorText: {
		color: colors.content_neutral,
	},
}));

export default WhoopsView;
