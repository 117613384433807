import { ColorValue } from 'react-native';
import { Path, Svg } from 'react-native-svg';

import useUITheme from '../hooks/useUITheme';

export default ({ fill }: { fill?: ColorValue }) => {
	const defaultFill = useUITheme().border_primary;
	return (
		<Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
			<Path
				d="M1 13L13 1M1 1L13 13"
				stroke={fill ?? defaultFill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</Svg>
	);
};
