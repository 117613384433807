import { PortableTextComponentProps } from '@portabletext/react';
import { ScienceBlockType } from '@wearemojo/sanity-schema';

import ScienceCard from '../cards/ScienceCard';
import CMSContent from '../CMSContent';
import CMSBlock from './CMSBlock';

type CMSScienceBlockProps = PortableTextComponentProps<ScienceBlockType>;
const CMSScienceBlock = ({
	value: { title, content },
}: CMSScienceBlockProps) => {
	return (
		<CMSBlock>
			<ScienceCard title={title}>
				<CMSContent value={content} normalTextVariant="body_lg" />
			</ScienceCard>
		</CMSBlock>
	);
};

export default CMSScienceBlock;
