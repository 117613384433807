import { PortableTextComponentProps } from '@portabletext/react';
import { AnimationType } from '@wearemojo/sanity-schema';
import { useMemo } from 'react';

import Animation from '../Animation';
import { useAudioPlayer } from '../utils/audio';
import CMSBlock from './CMSBlock';

type CMSAnimationProps = PortableTextComponentProps<AnimationType>;
const CMSAnimation = ({ value }: CMSAnimationProps) => {
	const { name, autoPlay, loop, lottieFile, accompanyingAudio } = value;
	const source = useMemo(() => {
		if (accompanyingAudio) {
			return { uri: accompanyingAudio.asset.url };
		}
	}, [accompanyingAudio]);
	const audioPlayer = useAudioPlayer(source);
	return (
		<CMSBlock>
			<Animation
				source={{ uri: lottieFile.asset.url }}
				autoPlay={autoPlay}
				loop={loop}
				audioPlayer={source && audioPlayer}
				eventFields={{ name }}
			/>
		</CMSBlock>
	);
};

export default CMSAnimation;
