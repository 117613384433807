import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import LightBulbIcon from 'react-native-heroicons/outline/LightBulbIcon';

import useUITheme from '../hooks/useUITheme';
import GenericCard from './GenericCard';

export type Props = {
	children: ReactNode;
	title: string;
};

const ConceptCard = ({ children, title }: Props) => {
	const stroke = useUITheme().content_primary;
	const icon = <LightBulbIcon stroke={stroke} size={24} style={styles.icon} />;
	return (
		<GenericCard title={title} icon={icon}>
			{children}
		</GenericCard>
	);
};

const styles = StyleSheet.create({
	icon: {
		marginTop: -2,
	},
});

export default ConceptCard;
