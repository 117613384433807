import { PortableTextComponentProps } from '@portabletext/react';
import { ContentCardListType, SQScorePillar } from '@wearemojo/sanity-schema';
import { spacing } from '@wearemojo/ui-constants';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import ContentCard from '../cards/ContentCard';
import Stack from '../Stack';
import Text from '../Text';
import { sqScoreThemeColors } from '../utils/SQScore';
import CMSBlock from './CMSBlock';

type Props = PortableTextComponentProps<ContentCardListType>;
const CMSContentCardList = ({ value }: Props) => {
	const { styles } = useStyles(stylesheet);

	return (
		<CMSBlock>
			<Stack spacing={spacing.md}>
				{value.cards.map((card, index) => (
					<ContentCard
						leadingItem={
							<Text
								variant="heading_xs"
								style={value.pillar && styles[value.pillar]}
							>
								{index + 1}
							</Text>
						}
						key={index}
						content={card.content.content}
					/>
				))}
			</Stack>
		</CMSBlock>
	);
};

const stylesheet = createStyleSheet(({ colors }) => ({
	...Object.values(SQScorePillar).reduce(
		(acc, pillar) => {
			acc[pillar] = {
				color: colors[sqScoreThemeColors[pillar]],
			};
			return acc;
		},
		{} as { [key in SQScorePillar]: { color: string } },
	),
}));

export default CMSContentCardList;
