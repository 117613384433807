import { darkTheme, UIThemeType } from '@wearemojo/ui-constants';
import { UnistylesRegistry } from 'react-native-unistyles';

UnistylesRegistry.addThemes({
	dark: darkTheme,
}).addConfig({
	initialTheme: 'dark',
});

declare module 'react-native-unistyles' {
	export interface UnistylesThemes extends UIThemeType {}
}
