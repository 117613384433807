import ArrowRightIcon from '@wearemojo/icons/Outline/Arrows/ArrowRightIcon';
import { BrandColor } from '@wearemojo/ui-constants';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import PressableOpacity from './PressableOpacity';
import Text from './Text';

// TODO: Change BrandColor to tokens

export type Props = {
	title: string;
	disabled?: boolean;
	onPress?: () => void;
	position?: 'start' | 'center' | 'end';
	variant?: 'bright' | 'dark';
	noPadding?: boolean;
};

const TextAction = ({
	title,
	disabled = false,
	onPress,
	position,
	variant = 'bright',
	noPadding = false,
}: Props) => {
	const compoundVariant = getCompoundVariant({ variant, disabled });
	const { styles } = useStyles(stylesheet, {
		compoundVariant,
		position,
		noPadding,
	});
	const iconColor = styles.text.color;

	return (
		<PressableOpacity
			onPress={!disabled ? onPress : undefined}
			disabled={disabled}
			style={styles.root}
		>
			<Text variant="action" style={styles.text}>
				{title}
			</Text>
			<ArrowRightIcon size={16} color={iconColor} stroke={iconColor} />
		</PressableOpacity>
	);
};

const getCompoundVariant = ({
	variant,
	disabled,
}: Pick<Props, 'variant' | 'disabled'>) => {
	if (variant === 'bright') {
		return disabled ? 'brightDisabled' : 'bright';
	}
	return disabled ? 'darkDisabled' : 'dark';
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: spacing.xs,
		paddingHorizontal: spacing.xl,
		paddingVertical: spacing.md,

		variants: {
			position: {
				start: {
					alignSelf: 'flex-start',
				},
				center: {
					alignSelf: 'center',
				},
				end: {
					alignSelf: 'flex-end',
				},
			},
			noPadding: {
				true: {
					paddingHorizontal: 0,
					paddingVertical: 0,
				},
			},
		},
	},
	text: {
		textTransform: 'uppercase',

		variants: {
			compoundVariant: {
				bright: {
					color: BrandColor.primary_yellow,
				},
				brightDisabled: {
					color: BrandColor.neutral_400,
				},
				dark: {
					color: BrandColor.black,
				},
				darkDisabled: {
					color: BrandColor.neutral_200, // @TODO: not yet represented in DS, update to match when present
				},
			},
		},
	},
}));

export default TextAction;
