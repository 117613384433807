import { ColorValue } from 'react-native';
import { Path, Svg } from 'react-native-svg';

import useUITheme from '../hooks/useUITheme';

export default ({ fill }: { fill?: ColorValue }) => {
	const defaultFill = useUITheme().border_primary;
	return (
		<Svg width="20" height="16" viewBox="0 0 20 16" fill="none">
			<Path
				d="M8 15L1 8m0 0l7-7M1 8h18"
				stroke={fill ?? defaultFill}
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</Svg>
	);
};
