import { ReactNode } from 'react';
import BeakerIcon from 'react-native-heroicons/outline/BeakerIcon';

import useUITheme from '../hooks/useUITheme';
import GenericCard from './GenericCard';

export type Props = {
	children: ReactNode;
	title: string;
};

const ScienceCard = ({ children, title }: Props) => {
	const stroke = useUITheme().content_primary;
	const icon = <BeakerIcon stroke={stroke} size={24} />;
	return (
		<GenericCard title={title} icon={icon}>
			{children}
		</GenericCard>
	);
};

export default ScienceCard;
