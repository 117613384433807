import { useCallback, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View, ViewProps } from 'react-native';

type Props = {
	// iOS on Safari >=14.8 doesn't support aspectRatio via style property
	aspectRatio?: number;
} & ViewProps;

const ViewRatio = ({ aspectRatio, children, style, ...viewProps }: Props) => {
	const [width, setWidth] = useState<number>();
	const onLayout = useCallback(
		({ nativeEvent: { layout } }: LayoutChangeEvent) => setWidth(layout.width),
		[],
	);
	const height = width && aspectRatio ? width / aspectRatio : undefined;
	return (
		<View
			style={[styles.root, { height, aspectRatio }, style]}
			onLayout={onLayout}
			{...viewProps}
		>
			{children}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		width: '100%',
	},
});

export default ViewRatio;
