import { PortableTextComponentProps } from '@portabletext/react';
import { AudioType } from '@wearemojo/sanity-schema';
import { useContext, useEffect, useMemo } from 'react';

import AudioPlayerContainer from '../AudioPlayerContainer';
import useUIContext from '../hooks/useUIContext';
import { useAudioPlayer } from '../utils/audio';
import CMSBlock from './CMSBlock';
import CMSContext from './CMSContext';

type CMSAudioProps = PortableTextComponentProps<AudioType>;
const CMSAudio = ({ value: { name, file } }: CMSAudioProps) => {
	const { pauseAudioOnUnmount } = useContext(CMSContext);
	const { isWeb } = useUIContext();
	const uri = file.asset.url;
	const source = useMemo(() => ({ uri }), [uri]);
	// Bug on native where preload causes the wrong audio to play, so only preload on web
	// TODO: Fix this when we redo the audio player
	const audioPlayer = useAudioPlayer(source, { preload: isWeb });

	useEffect(() => {
		if (pauseAudioOnUnmount) {
			return audioPlayer.pause;
		}
	}, [pauseAudioOnUnmount, audioPlayer]);

	return (
		<CMSBlock>
			<AudioPlayerContainer
				key={uri}
				audioPlayer={audioPlayer}
				eventFields={{ name }}
				variant={'inline'}
			/>
		</CMSBlock>
	);
};

export default CMSAudio;
