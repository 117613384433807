import { PortableTextComponentProps } from '@portabletext/react';
import { FlairType } from '@wearemojo/sanity-schema';
import { Spacing } from '@wearemojo/ui-constants';
import { StyleSheet, View } from 'react-native';

import Flair from '../Flair';
import Stack from '../Stack';
import Text from '../Text';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

type CMSFlairProps = PortableTextComponentProps<FlairType>;
const CMSFlair = ({ value: { heading, image, icon } }: CMSFlairProps) => {
	const { imgAttr } = useCMSGlobalContext();
	const { uri } = imgAttr(image);
	return (
		<CMSBlock>
			<Stack spacing={Spacing.large}>
				{heading && (
					<View style={styles.heading}>
						<Text variant="heading_sm">{heading}</Text>
					</View>
				)}
				<Flair icon={icon}>
					<Flair.Image source={{ uri }} />
				</Flair>
			</Stack>
		</CMSBlock>
	);
};

const styles = StyleSheet.create({
	heading: {
		alignItems: 'center',
	},
});

export default CMSFlair;
