import { PortableTextComponentProps } from '@portabletext/react';
import { QuoteCardBlockType } from '@wearemojo/sanity-schema';

import QuoteCard from '../cards/QuoteCard';
import useSanityImageAttributes from '../hooks/useSanityImageAttributes';
import CMSBlock from './CMSBlock';

type CMSQuoteCardProps = PortableTextComponentProps<QuoteCardBlockType>;

const CMSQuoteCardBlock = ({
	value: { content, author, authorImage },
}: CMSQuoteCardProps) => {
	const imgAttr = useSanityImageAttributes(200);
	const img = imgAttr(authorImage);
	return (
		<CMSBlock>
			<QuoteCard content={content} author={author} authorImage={img} />
		</CMSBlock>
	);
};

export default CMSQuoteCardBlock;
