import { borderRadius, Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { Image, ImageURISource, StyleSheet, View } from 'react-native';

import BackgroundView from './BackgroundView';
import Stack from './Stack';
import Text from './Text';

type Props = {
	author: string;
	byline?: string;
	children: ReactNode;
	authorImage?: ImageURISource;
};

const ExpertQuote = ({ children, author, byline, authorImage }: Props) => {
	return (
		<BackgroundView background="background_secondary" style={styles.root}>
			<View style={styles.content}>
				<Stack spacing={Spacing.large}>
					{children}
					<View style={styles.authorContainer}>
						{authorImage?.uri ? <AuthorImage source={authorImage} /> : null}
						<View>
							<Text weight="bold">{author}</Text>
							{byline ? <Text>{byline}</Text> : null}
						</View>
					</View>
				</Stack>
			</View>
		</BackgroundView>
	);
};

const AuthorImage = ({ source }: { source: ImageURISource }) => {
	return (
		<Image source={source} style={styles.authorImage} alt="author headshot" />
	);
};

const styles = StyleSheet.create({
	root: {
		borderRadius,
		flexDirection: 'row',
		overflow: 'hidden',
	},
	content: {
		flex: 1,
		padding: Spacing.large,
	},
	authorContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	authorImage: {
		height: 48,
		width: 48,
		borderRadius: 48 / 2,
		marginRight: Spacing.regular,
	},
});

export default ExpertQuote;
