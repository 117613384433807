import { ComponentType, ReactNode } from 'react';
import {
	ErrorBoundary as _ErrorBoundary,
	FallbackProps,
	withErrorBoundary as _withErrorBoundary,
} from 'react-error-boundary';

import ErrorBox from '../ErrorBox';

type Props = {
	label?: string;
	children: ReactNode;
};

export const ErrorBoundary = ({ label, children }: Props) => (
	<_ErrorBoundary FallbackComponent={fallbackComponent(label)}>
		{children}
	</_ErrorBoundary>
);

export function withErrorBoundary<P extends Object>(
	Component: ComponentType<P>,
) {
	return _withErrorBoundary(Component, {
		FallbackComponent: fallbackComponent(
			`${getComponentName(Component)} Error`,
		),
	});
}

export function getComponentName<P>(
	Component: ComponentType<P>,
	defaultName = 'Component',
) {
	return Component.displayName || Component.name || defaultName;
}

const fallbackComponent =
	(label?: string) =>
	({ error }: FallbackProps) => (
		<ErrorBox label={label} message={error.message} />
	);
