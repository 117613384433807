import { LinearGradient } from 'expo-linear-gradient';
import { Pressable, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import ActivityDot from './ActivityDot';
import useUIContext from './hooks/useUIContext';
import useUITheme from './hooks/useUITheme';
import Text from './Text';
import { NavBarLink } from './utils/types';

type Props = {
	links?: NavBarLink[];
	showFade?: boolean;
};

const TabBar = ({ links = [], showFade }: Props) => {
	const { isDesktop } = useUIContext();
	const {
		styles,
		theme: { colors },
	} = useStyles(stylesheet, { isDesktop });

	return (
		<SafeAreaView edges={['left', 'right', 'bottom']} style={styles.root}>
			<View style={styles.container}>
				{showFade && (
					<>
						<LinearGradient
							locations={[0, 0.7]}
							colors={[
								`${colors.background_primary}00`,
								`${colors.background_primary}ff`,
							]}
							style={styles.fade}
						/>
						<View style={styles.fadeBottom} />
					</>
				)}
				{links.map((link: NavBarLink) => (
					<Tab {...link} />
				))}
			</View>
		</SafeAreaView>
	);
};

const Tab = ({
	title,
	icon: Icon,
	isActive,
	linkProvider: LinkProvider,
	hasActivity,
}: NavBarLink) => {
	const { isDesktop } = useUIContext();
	const { styles } = useStyles(stylesheet, { isDesktop });
	const themeColor = isActive ? 'content_primary' : 'content_neutral';
	const iconColor = useUITheme()[themeColor];
	return (
		<LinkProvider>
			<Pressable style={styles.tab}>
				<View style={styles.tabInner}>
					<View style={styles.tabIcon}>
						<Icon color={iconColor} />
						{hasActivity && <ActivityDot style={styles.tabActivityDot} />}
					</View>
					<View style={styles.tabLabel}>
						<Text
							variant="body_sm"
							style={styles.tabText}
							themeColor={themeColor}
							/*
								We (roughly) cap titles to wrapping to 2 lines, any more covers
								too much of the main content area and items are inaccessible
							*/
							maxFontSizeMultiplier={2.5}
						>
							{title}
						</Text>
					</View>
				</View>
			</Pressable>
		</LinkProvider>
	);
};

TabBar.Height = 70;
TabBar.HeightDesktop = 50;

const FADE_OVERFLOW = 20;

const stylesheet = createStyleSheet(({ spacing, colors }) => ({
	root: {
		minHeight: TabBar.Height,
	},
	fade: {
		...StyleSheet.absoluteFillObject,
		top: -FADE_OVERFLOW,
		pointerEvents: 'none',
	},
	fadeBottom: {
		position: 'absolute',
		top: '100%',
		left: 0,
		right: 0,
		height: 420, // Arbitrary large-ish value to always cover bottom safe area
		backgroundColor: `${colors.background_primary}ff`,
		variants: {
			isDesktop: {
				true: {
					minHeight: TabBar.HeightDesktop,
				},
			},
		},
	},
	container: {
		flexDirection: 'row',
		paddingHorizontal: spacing.lg,
	},
	tab: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: spacing.lg,
	},
	tabInner: {
		alignItems: 'center',
		paddingTop: spacing.xs2,
		variants: {
			isDesktop: {
				true: {
					flexDirection: 'row',
					paddingHorizontal: spacing.xl4,
					paddingBottom: spacing.xs,
				},
			},
		},
	},
	tabIcon: {
		alignItems: 'center',
		marginHorizontal: spacing.sm,
		variants: {
			isDesktop: {
				true: {
					marginLeft: 0,
				},
			},
		},
	},
	tabLabel: {
		paddingVertical: spacing.xs2,
	},
	tabActivityDot: {
		position: 'absolute',
		top: -2,
		right: -3,
	},
	tabText: {
		textAlign: 'center',
	},
}));

export default TabBar;
