import { PortableTextComponentProps } from '@portabletext/react';
import { VideoType } from '@wearemojo/sanity-schema';

import useUIContext from '../hooks/useUIContext';
import { useApiState } from '../utils/apiState';
import { useCMSGlobalContext } from '../utils/cms';
import Video, { VideoConfig } from '../Video';
import CMSBlock from './CMSBlock';

// TODO: get analytics working for videos
type CMSVideoProps = PortableTextComponentProps<VideoType>;
const CMSVideo = ({ value }: CMSVideoProps) => {
	const { isIos } = useUIContext();
	const { imgAttr } = useCMSGlobalContext();
	const {
		name,
		cloudflareVideoId,
		mp4VideoUrl,
		poster,
		autoplay,
		aspectRatio,
		maxHeightPercentage,
		useNativePlayerOnMobile,
	} = value;
	const posterUri = poster ? imgAttr(poster).uri : undefined;
	// Lets allow native player only on iOS. On Android the iframe works fine.
	const useNativePlayer = isIos && !!useNativePlayerOnMobile;
	const preferDirectVideo = useApiState().preferDirectVideo;

	const config: VideoConfig | undefined = (() => {
		if (preferDirectVideo?.isLoading) return;

		if (mp4VideoUrl && preferDirectVideo?.isOn) {
			return {
				type: 'uri',
				uri: mp4VideoUrl,
			};
		}

		return {
			type: 'cloudflare',
			videoId: cloudflareVideoId,
			posterUri,
		};
	})();

	if (!config) return null;

	return (
		<CMSBlock>
			<Video
				config={{
					...config,
					autoplay,
				}}
				eventFields={{
					name,
					cloudflare_video_id: cloudflareVideoId,
				}}
				aspectRatio={aspectRatio}
				maxHeightPercentage={maxHeightPercentage}
				useNativePlayer={useNativePlayer}
			/>
		</CMSBlock>
	);
};

export default CMSVideo;
