import { PortableTextComponentProps } from '@portabletext/react';
import { ContentCardBlockType } from '@wearemojo/sanity-schema';

import ContentCard from '../cards/ContentCard';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

type CMSContentCardProps = PortableTextComponentProps<ContentCardBlockType>;

const CMSContentCardBlock = ({ value }: CMSContentCardProps) => {
	const { imgAttr } = useCMSGlobalContext();
	const image = value.image ? imgAttr(value.image) : undefined;

	return (
		<CMSBlock>
			<ContentCard
				content={value.content.content}
				image={image && { uri: image?.uri }}
			/>
		</CMSBlock>
	);
};

export default CMSContentCardBlock;
