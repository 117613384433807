import { SQScorePillar, sqScorePillarsOrdered } from '@wearemojo/sanity-schema';
import { themeColors, UITheme, UIThemeColors } from '@wearemojo/ui-constants';

export type SQPillarScore = {
	pillar: SQScorePillar;
	value: number; // 0-25 integer
};

export const sqScoreMaxOverallValue = 100;
export const sqScoreMaxPillarValue = Math.floor(
	sqScoreMaxOverallValue / sqScorePillarsOrdered.length,
);

export const sqScoreThemeColors = {
	[SQScorePillar.sexualFitness]: 'fill_score_sexualfitness',
	[SQScorePillar.connectionBuilding]: 'fill_score_connection',
	[SQScorePillar.mentalResilience]: 'fill_score_resilience',
	[SQScorePillar.stimulationResponse]: 'fill_score_stimulation',
} satisfies Record<SQScorePillar, keyof UIThemeColors>;

export const sqScoreGetPillarColor = (pillar: SQScorePillar, theme: UITheme) =>
	sqScorePillarColors[theme][pillar];

const sqScorePillarColors = {
	[UITheme.dark]: {
		[SQScorePillar.sexualFitness]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.sexualFitness]
			],
		[SQScorePillar.connectionBuilding]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.connectionBuilding]
			],
		[SQScorePillar.mentalResilience]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.mentalResilience]
			],
		[SQScorePillar.stimulationResponse]:
			themeColors[UITheme.dark][
				sqScoreThemeColors[SQScorePillar.stimulationResponse]
			],
	},
};

export const sqScoreOrderMap = sqScorePillarsOrdered.reduce(
	(acc, pillar, index) => {
		acc[pillar] = index;
		return acc;
	},
	{} as Record<SQScorePillar, number>,
);

export const sqScoreZero: SQPillarScore[] = sqScorePillarsOrdered.map(
	(pillar) => ({
		pillar,
		value: 0,
	}),
);

export const sqScoreMax: SQPillarScore[] = sqScorePillarsOrdered.map(
	(pillar) => ({
		pillar,
		value: sqScoreMaxPillarValue,
	}),
);

const isValidSQScorePillar = (value: string): value is SQScorePillar => {
	return Object.values(SQScorePillar).includes(value as SQScorePillar);
};

export const stringToSQScorePillar = (
	value: string,
): SQScorePillar | undefined => {
	if (isValidSQScorePillar(value)) return value;
	return undefined;
};
