import { PortableTextComponentProps } from '@portabletext/react';
import { ConceptBlockType } from '@wearemojo/sanity-schema';

import ConceptCard from '../cards/ConceptCard';
import CMSContent from '../CMSContent';
import CMSBlock from './CMSBlock';

type CMSConceptBlockProps = PortableTextComponentProps<ConceptBlockType>;
const CMSConceptBlock = ({
	value: { title, content },
}: CMSConceptBlockProps) => {
	return (
		<CMSBlock>
			<ConceptCard title={title || 'New concept'}>
				<CMSContent value={content} normalTextVariant="body_lg" />
			</ConceptCard>
		</CMSBlock>
	);
};

export default CMSConceptBlock;
