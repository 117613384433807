import { ReactNode, useContext } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Block from '../Block';
import CMSContext from './CMSContext';

type Props = {
	children: ReactNode;
	style?: StyleProp<ViewStyle>;
};

const CMSBlock = ({ children, style }: Props) => {
	const { spacing } = useContext(CMSContext);
	return (
		<Block spacing={spacing} style={style}>
			{children}
		</Block>
	);
};

export default CMSBlock;
