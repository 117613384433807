import { UIPlatform, UITheme } from '@wearemojo/ui-constants';
import { useContext, useMemo } from 'react';

import UIContext from '../UIContext';
import { UIFormFactor } from '../utils/types';

const useUIContext = () => {
	const { theme, platform, formFactor, reduceTransparency } =
		useContext(UIContext);

	return useMemo(
		() => ({
			theme,
			isDark: theme === UITheme.dark,
			platform,
			isWeb: platform === UIPlatform.web,
			isIos: platform === UIPlatform.ios,
			isAndroid: platform === UIPlatform.android,
			isNative: platform !== UIPlatform.web,
			formFactor,
			isMobile: formFactor === UIFormFactor.mobile,
			isDesktop: formFactor === UIFormFactor.desktop,
			reduceTransparency,
		}),
		[theme, platform, formFactor, reduceTransparency],
	);
};

export default useUIContext;
