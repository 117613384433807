import {
	BlockContentType,
	BlockContentWrapperType,
	IconType,
	InlineContentType,
	InlineContentWrapperType,
} from '@wearemojo/sanity-schema';
import { UIPlatform, UITheme } from '@wearemojo/ui-constants';
import { ComponentType, RefObject } from 'react';
import { PressableStateCallbackType, ScrollView } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { LinkProviderType } from './links';
import { ErrorType } from './whoops';

export enum TextVariant {
	heading_xl = 'heading_xl',
	heading_lg = 'heading_lg',
	heading_md = 'heading_md',
	heading_sm = 'heading_sm',
	heading_xs = 'heading_xs',
	heading_xxs = 'heading_xxs',
	action = 'action',
	body_xl = 'body_xl',
	body_lg = 'body_lg',
	body_md = 'body_md',
	body_sm = 'body_sm',
	label_md = 'label_md',
	label_sm = 'label_sm',
}

export enum TextWeight {
	regular = 'regular',
	bold = 'bold',
	heading = 'heading',
	headingBold = 'headingBold',
	headingExtraBold = 'headingExtraBold',
}

export enum ButtonType {
	primary = 'primary',
	secondary = 'secondary',
	warning = 'warning',
}

// Types accept string values, rather than enforcing use of enum reference
export type TextVariantish = TextVariant | keyof typeof TextVariant;
export type TextWeightish = TextWeight | keyof typeof TextWeight;
export type ButtonTypish = ButtonType | keyof typeof ButtonType;

export enum UIFormFactor {
	desktop = 'desktop',
	mobile = 'mobile',
}

export type UIFlags = {};

export type UIContext = {
	theme: UITheme;
	platform: UIPlatform;
	formFactor: UIFormFactor;
	flags: UIFlags;
	reduceTransparency: boolean;
};

export interface Heroicon extends SvgProps {
	size?: number;
}

export interface MojoIcon extends SvgProps {
	size?: number;
}

export interface PressableCallback extends PressableStateCallbackType {
	// Add web types
	focused: boolean;
	hovered: boolean;
}

export type NavBarLink = {
	title: string;
	key: string;
	icon: ComponentType<MojoIcon>;
	linkProvider: LinkProviderType;
	isActive?: boolean;
	hasActivity?: boolean;
};

export type ScrollViewRef = {
	scrollViewRef?: RefObject<ScrollView>;
};

export type InlineContent =
	| string
	| InlineContentType
	| InlineContentWrapperType;

export type BlockContent = BlockContentType | BlockContentWrapperType;

export type ContentVariables = Record<string, unknown>;

export type InlineErrorContent = {
	content: InlineContent;
	contentVariables: ContentVariables & {
		error: object;
	};
};

export type WhoopsError = {
	actions: ActionLink[];
	error: object;
	messages: InlineErrorContent[];
	title: InlineErrorContent;
	type: keyof typeof ErrorType;
};

export type ActionLink = {
	// @TODO: this can be more generic and used for using Actions within CMSContent
	title: string;
	linkProvider: LinkProviderType;
};

export type PollQuestionAnswerModalProps = {
	title: string;
	content: BlockContent;
	icon?: IconType;
	ctaText: string;
	onContinuePress: VoidFunction;
};
