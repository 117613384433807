import { PortableTextComponentProps } from '@portabletext/react';
import PinCircleIcon from '@wearemojo/icons/Outline/EssentionalUI/PinCircleIcon';
import { MemorableBlockType } from '@wearemojo/sanity-schema';

import CalloutCard from '../cards/CalloutCard';
import CMSContent from '../CMSContent';
import CMSBlock from './CMSBlock';

type CMSMemorableBlockProps = PortableTextComponentProps<MemorableBlockType>;
const CMSMemorableBlock = ({
	value: { title, content },
}: CMSMemorableBlockProps) => {
	return (
		<CMSBlock>
			<CalloutCard
				title={title || 'Memorable bit'}
				type="purple"
				icon={PinCircleIcon}
			>
				<CMSContent value={content} normalTextVariant="body_lg" />
			</CalloutCard>
		</CMSBlock>
	);
};

export default CMSMemorableBlock;
