import { ReactNode, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
	useAnimatedKeyboard,
	useAnimatedStyle,
	useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
	children: ReactNode;
	insideSafeArea?: boolean;
};

const options = {
	isStatusBarTranslucentAndroid: true,
	isNavigationBarTranslucentAndroid: true,
};

const ViaTranslate = ({ children }: Props) => {
	const { height } = useAnimatedKeyboard(options);

	const animatedStyles = useAnimatedStyle(() => ({
		transform: [{ translateY: -height.value }],
	}));

	return (
		<Animated.View style={[styles.root, animatedStyles]}>
			{children}
		</Animated.View>
	);
};

const ViaPadding = ({ children, insideSafeArea }: Props) => {
	const { height } = useAnimatedKeyboard(options);
	const insets = useSafeAreaInsets();
	const bottomSafeArea = useSharedValue(insideSafeArea ? insets.bottom : 0);
	useEffect(() => {
		bottomSafeArea.value = insideSafeArea ? insets.bottom : 0;
	}, [insideSafeArea, bottomSafeArea, insets.bottom]);

	const animatedStyles = useAnimatedStyle(() => ({
		paddingBottom: height.value - bottomSafeArea.value,
	}));

	return (
		<Animated.View style={[styles.root, animatedStyles]}>
			{children}
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
	},
});

export default {
	ViaTranslate,
	ViaPadding,
};
