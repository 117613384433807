import { PortableTextComponentProps } from '@portabletext/react';
import { TrackingButtonType } from '@wearemojo/sanity-schema';
import { useMemo } from 'react';

import TrackingButton from '../TrackingButton';
import CMSBlock from './CMSBlock';

type CMSTrackingButtonProps = PortableTextComponentProps<TrackingButtonType>;
const CMSTrackingButton = ({ value }: CMSTrackingButtonProps) => {
	const {
		buttonType,
		buttonTitle,
		buttonTitlePressed,
		trackingKey,
		trackingParams,
	} = value;

	const params = useMemo(() => {
		if (!trackingParams || !trackingParams.length) {
			return;
		}

		return trackingParams.reduce(
			(acc, param) => ({
				...acc,
				[param.key]: param.value,
			}),
			{} as Record<string, string>,
		);
	}, [trackingParams]);

	return (
		<CMSBlock>
			<TrackingButton
				type={buttonType}
				title={buttonTitle}
				titlePressed={buttonTitlePressed}
				trackingKey={trackingKey}
				trackingParams={params}
			/>
		</CMSBlock>
	);
};

export default CMSTrackingButton;
