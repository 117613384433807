import { BrandColor } from '@wearemojo/ui-constants';
import { ComponentType, ReactNode } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Text from '../Text';
import { MojoIcon } from '../utils/types';

export type Props = {
	title?: string;
	icon: ComponentType<MojoIcon>;
	type: 'green' | 'purple' | 'blue' | 'yellow' | 'orange';
	children?: ReactNode;
};

const CalloutCard = ({ title, type, icon: Icon, children }: Props) => {
	const { styles } = useStyles(stylesheet, { type });

	return (
		<View style={styles.root}>
			<Icon color={styles.icon.color} />
			<View style={styles.contentContainer}>
				{!!title && <Text variant="heading_xs">{title}</Text>}
				{children}
			</View>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius }) => ({
	root: {
		padding: spacing.lg,
		borderRadius: radius.md,
		borderTopWidth: 1,
		gap: spacing.sm,
		variants: {
			type: {
				green: {
					borderTopColor: BrandColor.primary_green,
					backgroundColor: `${BrandColor.primary_green}0D`,
				},
				purple: {
					borderTopColor: BrandColor.primary_purple,
					backgroundColor: `${BrandColor.primary_purple}0D`,
				},
				blue: {
					borderTopColor: BrandColor.primary_blue,
					backgroundColor: `${BrandColor.primary_blue}0D`,
				},
				orange: {
					borderTopColor: BrandColor.primary_orange,
					backgroundColor: `${BrandColor.primary_orange}0D`,
				},
				yellow: {
					borderTopColor: BrandColor.primary_yellow,
					backgroundColor: `${BrandColor.primary_yellow}0D`,
				},
			},
		},
	},
	icon: {
		variants: {
			type: {
				green: {
					color: BrandColor.primary_green,
				},
				purple: {
					color: BrandColor.primary_purple,
				},
				blue: {
					color: BrandColor.primary_blue,
				},
				orange: {
					color: BrandColor.primary_orange,
				},
				yellow: {
					color: BrandColor.primary_yellow,
				},
			},
		},
	},
	contentContainer: {
		gap: spacing.xs,
	},
}));

export default CalloutCard;
