import { PortableTextComponentProps } from '@portabletext/react';
import { WarningBlockType } from '@wearemojo/sanity-schema';

import WarningCard from '../cards/WarningCard';
import CMSContent from '../CMSContent';
import CMSBlock from './CMSBlock';

type CMSWarningBlockProps = PortableTextComponentProps<WarningBlockType>;
const CMSWarningBlock = ({
	value: { title, content },
}: CMSWarningBlockProps) => {
	return (
		<CMSBlock>
			<WarningCard title={title || 'Please note'}>
				<CMSContent value={content} />
			</WarningCard>
		</CMSBlock>
	);
};

export default CMSWarningBlock;
