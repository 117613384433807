import { PortableTextComponentProps } from '@portabletext/react';
import { BillingBlockType } from '@wearemojo/sanity-schema';

import BillingCard from '../cards/BillingCard';
import CMSContent from '../CMSContent';
import CMSBlock from './CMSBlock';

type CMSBillingBlockProps = PortableTextComponentProps<BillingBlockType>;
const CMSBillingBlock = ({
	value: { title, content },
}: CMSBillingBlockProps) => {
	return (
		<CMSBlock>
			<BillingCard title={title}>
				<CMSContent value={content} normalTextVariant="body_lg" />
			</BillingCard>
		</CMSBlock>
	);
};

export default CMSBillingBlock;
