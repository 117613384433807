import CheckCircleIcon from '@wearemojo/icons/Bold/EssentionalUI/CheckCircleIcon';
import DangerCircleIcon from '@wearemojo/icons/Bold/EssentionalUI/DangerCircleIcon';
import ShieldWarningIcon from '@wearemojo/icons/Bold/Security/ShieldWarningIcon';
import {
	borderRadius,
	BrandColor,
	Font,
	Spacing,
	themeColors,
	UITheme,
} from '@wearemojo/ui-constants';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
	NativeSyntheticEvent,
	TextInput as RNTextInput,
	TextInputProps,
	TextInputScrollEventData,
	TextStyle,
	View,
} from 'react-native';
import Animated, {
	Easing,
	useAnimatedStyle,
	useSharedValue,
	withTiming,
} from 'react-native-reanimated';
import {
	createStyleSheet,
	UnistylesValues,
	useStyles,
} from 'react-native-unistyles';

import useUIContext from './hooks/useUIContext';
import Stack from './Stack';
import Text from './Text';
import { TextVariant, UIFormFactor } from './utils/types';
import { baseSizes, getVariantStyle } from './utils/typography';

export type TextInputStatus = 'default' | 'validated' | 'warning' | 'error';

export type Props = {
	onChangeText?: (text: string) => void;
	initialValue?: string;
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	label?: string;
	multiline?: boolean;
	status?: TextInputStatus;
	statusMessage?: string;
} & Omit<TextInputProps, 'style'>;

export const textInputWebDataSelector = 'mojo-ui-textinput';

const dataAttrs = {
	dataSet: {
		[textInputWebDataSelector]: '', // Used as hook for CSS selector on web
	},
};

export type Ref = {
	focus: () => void;
	isFocused: () => boolean | undefined;
};

const TextInput = forwardRef<Ref, Props>(
	(
		{
			onChangeText,
			initialValue,
			value,
			disabled,
			label,
			placeholder,
			multiline,
			status = 'default',
			statusMessage,
			...textInputProp
		},
		ref,
	) => {
		const [isFocused, setIsFocused] = useState(false);
		const [localValue, setLocalValue] = useState(value || initialValue);
		const hasValue = value || localValue;
		const showLabelAsPlaceholder = (!isFocused || !!disabled) && !hasValue;
		const [hideLabel, setHideLabel] = useState(false);
		const hasLabel = !!label;
		const onChange = (text: string) => {
			setLocalValue(text);
			onChangeText?.(text);
		};
		const inputRef = useRef<RNTextInput>(null);

		useImperativeHandle(ref, () => ({
			focus: () => inputRef.current?.focus(),
			isFocused: () => inputRef.current?.isFocused(),
		}));

		const { isAndroid } = useUIContext();
		const onScroll = ({ nativeEvent }: TextInputScrollEvent) => {
			if (
				!inputRef.current ||
				/*
				Inconsistent behavior between Android and iOS/web. On Android the
				scrollable area of the TextInput is the section within the padding and
				any overflow is cut off, where as web and iOS scroll the text visibly
				behind the label, as the whole TextInput area is scrollable (arguably
				the more correct behavior). Rather than mess around and likely creating
				a worse UX outside of Android (i.e. moving the padding and reducing the
				scrollable region), we can keep the label visible on Android since the
				content never appears under the label due to the overflow cut off.
			*/
				isAndroid
			) {
				return;
			}
			// @ts-ignore-next-line - Web API
			const pos = inputRef.current?.scrollTop || nativeEvent?.contentOffset?.y;
			setHideLabel((pos || 0) > 0);
		};

		/*
		We only show the placeholder on focus, however we otherwise hide it by just
		matching the background color so screen readers still get the value up front
	*/
		const placeholderColor = isFocused
			? BrandColor.neutral_200
			: INPUT_BACKGROUND_COLOR;

		const { styles } = useStyles(stylesheet, {
			multiline,
			disabled,
			focused: isFocused,
			hasLabel,
			showLabelAsPlaceholder,
		});

		// Track input and label heights to support large font sizes and long labels
		const [inputHeight, setInputHeight] = useState(
			multiline ? INPUT_MULTILINE_HEIGHT : INPUT_HEIGHT,
		);
		const [labelHeight, setLabelHeight] = useState(INITIAL_LABEL_HEIGHT);
		const paddingTop = labelHeight + Spacing.regular;

		return (
			<Stack spacing={Spacing.small}>
				<View
					style={styles.inputWrap}
					accessible
					accessibilityLabel={label}
					onLayout={(event) => setInputHeight(event.nativeEvent.layout.height)}
				>
					<RNTextInput
						ref={inputRef}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						placeholder={placeholder}
						placeholderTextColor={placeholderColor}
						multiline={multiline}
						textAlignVertical={multiline ? 'top' : 'center'}
						style={[
							styles.input,
							labelHeight > INITIAL_LABEL_HEIGHT && { paddingTop },
						]}
						onScroll={onScroll}
						onChangeText={onChange}
						defaultValue={initialValue}
						value={value}
						editable={!disabled}
						aria-disabled={disabled}
						{...dataAttrs}
						{...textInputProp}
					/>
					{label && (
						<Label
							text={label}
							asPlaceholder={showLabelAsPlaceholder}
							disabled={disabled}
							multiline={multiline}
							inputHeight={inputHeight}
							labelHeight={labelHeight}
							setLabelHeight={setLabelHeight}
							hideLabel={hideLabel}
						/>
					)}
					<View style={styles.iconWrap}>
						<StatusIcon status={status} />
					</View>
				</View>
				{statusMessage && (
					<StatusMessage status={status} message={statusMessage} />
				)}
			</Stack>
		);
	},
);

const Label = ({
	text,
	asPlaceholder,
	disabled,
	multiline,
	inputHeight,
	labelHeight,
	setLabelHeight,
	hideLabel,
}: {
	text: string;
	asPlaceholder: boolean;
	disabled?: boolean;
	multiline?: boolean;
	inputHeight: number;
	labelHeight: number;
	setLabelHeight(height: number): void;
	hideLabel: boolean;
}) => {
	const { styles } = useStyles(stylesheet, {
		disabled,
		showLabelAsPlaceholder: asPlaceholder,
	});
	const labelStyle = useLabelStyle({
		asPlaceholder: asPlaceholder && !multiline,
		hideLabel,
		labelHeight,
		inputHeight,
	});

	const labelTextStyle = useLabelTextStyle(asPlaceholder);
	return (
		<>
			<Animated.View style={[styles.label, labelStyle]}>
				<Animated.Text style={[styles.labelText as TextStyle, labelTextStyle]}>
					{text}
				</Animated.Text>
			</Animated.View>
			<LabelHeightSensor text={text} setLabelHeight={setLabelHeight} />
		</>
	);
};

const StatusMessage = ({
	status,
	message,
}: {
	status: TextInputStatus;
	message: string;
}) => {
	const { styles } = useStyles(stylesheet, {
		status: status !== 'default' ? status : undefined,
	});
	return (
		<View style={styles.statusMessage}>
			<Text variant="body_sm" style={styles.statusMessageText}>
				{message}
			</Text>
		</View>
	);
};

const BASE_TIMING_CONFIG = {
	duration: 250,
	easing: Easing.elastic(2),
};

const LabelHeightSensor = ({
	text,
	setLabelHeight,
}: {
	text: string;
	setLabelHeight(height: number): void;
}) => {
	const { styles } = useStyles(stylesheet);
	return (
		<View
			style={[styles.label, styles.labelSensor]}
			onLayout={(event) => {
				setLabelHeight(
					Math.max(event.nativeEvent.layout.height, INITIAL_LABEL_HEIGHT),
				);
			}}
		>
			<Text
				selectable={false}
				style={[
					styles.labelText as TextStyle,
					/*
						We track the size of the label with the (larger) placeholder font
						size, this ensures the space reserved for the label will always be
						large enough to fit the (larger) placeholder text, however when
						focused the label will animate to the smaller size. When wrapping to
						over 3 lines this leaves a bit of extra spacing between the label
						and the input, this is a bit of a trade off as we'd otherwise need
						to toggle the height of the input when focusing, which would be a
						bit jarring
					*/
					{ fontSize: LABEL_FONT_SIZE_PLACEHOLDER },
				]}
			>
				{text ?? ' ' /* Empty string alone doesn't trigger layout */}
			</Text>
		</View>
	);
};

const useLabelStyle = ({
	asPlaceholder,
	inputHeight,
	hideLabel,
	labelHeight,
}: {
	asPlaceholder: boolean;
	hideLabel: boolean;
	inputHeight: number;
	labelHeight: number;
}) => {
	const value = asPlaceholder
		? inputHeight / 2 - labelHeight / 2
		: Spacing.regular;
	const top = useSharedValue<number>(value);
	const opacity = useSharedValue<number>(hideLabel ? 0 : 1);
	top.value = withTiming(value, BASE_TIMING_CONFIG);
	opacity.value = withTiming(hideLabel ? 0 : 1, {
		...BASE_TIMING_CONFIG,
		easing: Easing.ease,
	});
	return useAnimatedStyle(() => {
		return {
			opacity: opacity.value,
			top: top.value,
		};
	});
};

const useLabelTextStyle = (asPlaceholder: boolean) => {
	const value = asPlaceholder
		? LABEL_FONT_SIZE_PLACEHOLDER
		: LABEL_FONT_SIZE_FOCUSED;
	const fontSize = useSharedValue<number>(value);
	fontSize.value = withTiming(value, BASE_TIMING_CONFIG);
	return useAnimatedStyle(() => {
		return {
			fontSize: fontSize.value,
		};
	});
};

const StatusIcon = ({ status }: { status: TextInputStatus }) => {
	switch (status) {
		case 'validated':
			return <CheckCircleIcon fill={BrandColor.primary_green} />;
		case 'warning':
			return <DangerCircleIcon fill={BrandColor.primary_orange_darker} />;
		case 'error':
			return <ShieldWarningIcon fill={BrandColor.red} />;
		default:
			return null;
	}
};

const INPUT_HEIGHT = 65;
const INPUT_MULTILINE_HEIGHT = 120;
const INPUT_BORDER_WIDTH = 1;
const INPUT_BACKGROUND_COLOR = BrandColor.neutral_700;
const ICON_SIZE = 24;
const INITIAL_LABEL_HEIGHT = 18;
const LABEL_FONT_SIZE_PLACEHOLDER = baseSizes.textSm.fontSize;
const LABEL_FONT_SIZE_FOCUSED = baseSizes.textXs.fontSize;

const stylesheet = createStyleSheet(({ spacing }) => ({
	inputWrap: {
		position: 'relative',
	},
	input: {
		borderWidth: INPUT_BORDER_WIDTH,
		borderRadius,
		padding: spacing.md,
		fontSize: LABEL_FONT_SIZE_PLACEHOLDER,
		fontFamily: Font.body,
		backgroundColor: INPUT_BACKGROUND_COLOR,
		borderColor: BrandColor.neutral_500,
		minHeight: INPUT_HEIGHT,
		color: themeColors[UITheme.dark].content_primary,
		variants: {
			focused: {
				true: {
					borderColor: BrandColor.primary_yellow_lighter,
				},
			},
			multiline: {
				true: {
					height: INPUT_MULTILINE_HEIGHT,
				},
			},
			hasLabel: {
				true: {
					paddingTop: spacing.md * 2,
				},
			},
			disabled: {
				true: {
					color: BrandColor.neutral_600,
					borderColor: BrandColor.neutral_600,
				},
			},
		},
	},
	label: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		paddingHorizontal: spacing.md,
		pointerEvents: 'none',
	},
	labelSensor: {
		opacity: 0,
		backgroundColor: 'rgba(255,0,0,0.8)',
	},
	labelText: {
		...(getVariantStyle(
			TextVariant.label_sm,
			UIFormFactor.mobile,
		) as UnistylesValues),
		color: themeColors[UITheme.dark].content_neutral,
		variants: {
			showLabelAsPlaceholder: {
				true: {
					color: themeColors[UITheme.dark].content_neutral100,
				},
			},
			disabled: {
				true: {
					color: BrandColor.neutral_500,
				},
			},
		},
	},
	iconWrap: {
		position: 'absolute',
		top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
		right: spacing.md,
		width: ICON_SIZE,
		height: ICON_SIZE,
		pointerEvents: 'none',

		variants: {
			multiline: {
				true: {
					top: spacing.sm,
				},
			},
		},
	},
	statusMessage: {
		paddingHorizontal: spacing.md,
	},
	statusMessageText: {
		variants: {
			status: {
				warning: {
					color: BrandColor.primary_orange_darker,
				},
				error: {
					color: themeColors[UITheme.dark].content_warning,
				},
				default: {},
				validated: {},
			},
		},
	},
}));

type TextInputScrollEvent = NativeSyntheticEvent<TextInputScrollEventData>;

export default TextInput;
