import { PortableTextComponentProps } from '@portabletext/react';
import { NumberedListType } from '@wearemojo/sanity-schema';

import NumberedList from '../NumberedList';
import CMSBlock from './CMSBlock';

type CMSNumberedListProps = PortableTextComponentProps<NumberedListType>;
const CMSNumberedList = ({ value: { items = [] } }: CMSNumberedListProps) => (
	<CMSBlock>
		<NumberedList items={items} />
	</CMSBlock>
);

export default CMSNumberedList;
