import { ComponentType, useEffect } from 'react';
import {
	ImageBackground,
	Pressable,
	StyleProp,
	StyleSheet,
	TextStyle,
	View,
} from 'react-native';
import CheckCircleIcon from 'react-native-heroicons/solid/CheckCircleIcon';
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withTiming,
} from 'react-native-reanimated';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Text from './Text';
import { LinkProviderType } from './utils/links';
import { MojoIcon, PressableCallback } from './utils/types';

type OptionProps = {
	title: string;
	leftIcon?: ComponentType<MojoIcon>;
	percentage?: number;
	selected?: boolean;
	barPercentage?: number;
	onPress?: () => void;
	showResult?: boolean;
	linkProvider?: LinkProviderType;
	textStyle?: StyleProp<TextStyle>;
};

const OptionsButton = ({
	title,
	leftIcon: LeftIcon,
	percentage = 0,
	selected = false,
	barPercentage = 1,
	onPress,
	showResult = false,
	textStyle,
	linkProvider: LinkProvider,
}: OptionProps) => {
	const {
		styles,
		theme: { colors, iconSize },
	} = useStyles(stylesheet);

	const formattedPercentage = showResult
		? `${Math.round(percentage * 100)}%`
		: undefined;

	const disabled = !onPress;
	const showSelected = selected && !showResult;

	const widthAnim = useSharedValue(0);
	const animatedStyle = useAnimatedStyle(() => ({
		width: `${widthAnim.value * 100}%`,
	}));

	useEffect(() => {
		widthAnim.value = withTiming(barPercentage, {
			duration: 300,
		});
	}, [widthAnim, barPercentage]);

	const button = (
		<Pressable style={styles.pressable} onPress={onPress} disabled={disabled}>
			{(state) => {
				const { hovered, focused } = state as PressableCallback;

				const isHovered = hovered || focused;
				return (
					<View style={styles.optionContainer}>
						{showSelected && (
							<ImageBackground
								source={require('./assets/patterns/fill_1.png')}
								style={StyleSheet.absoluteFillObject}
							/>
						)}
						<View style={StyleSheet.absoluteFillObject}>
							<Animated.View
								style={[
									styles.optionBar,
									showResult && !showSelected && animatedStyle,
								]}
							/>
						</View>
						<View style={styles.optionContent}>
							<View style={styles.iconTextContainer}>
								{LeftIcon && (
									<LeftIcon
										color={
											showSelected
												? colors.content_on_light
												: colors.content_neutral100
										}
										size={iconSize.sm}
									/>
								)}
								<Text
									variant="body_md"
									themeColor={
										showSelected ? 'content_on_light' : 'content_neutral100'
									}
									style={[styles.titleStyle, textStyle]}
								>
									{title}
								</Text>
							</View>
							{!!formattedPercentage && (
								<Text variant="body_md">{formattedPercentage}</Text>
							)}
							{showSelected && (
								<CheckCircleIcon
									color={colors.background_primary}
									size={iconSize.sm}
								/>
							)}
						</View>
						<View
							style={[
								styles.optionTrigger,
								isHovered && !disabled && styles.optionTriggerHovered,
								selected && !showSelected && styles.optionTriggerSelected,
							]}
						/>
					</View>
				);
			}}
		</Pressable>
	);

	if (LinkProvider) return <LinkProvider>{button}</LinkProvider>;

	return button;
};

const stylesheet = createStyleSheet(({ radius, colors, spacing }) => ({
	pressable: {
		borderRadius: radius.full,
	},
	optionContainer: {
		position: 'relative',
		borderRadius: radius.full,
		backgroundColor: colors.poll_background,
		overflow: 'hidden',
	},
	optionBar: {
		height: '100%',
		borderRadius: radius.full,
		width: 0,
		opacity: 0.2,
		backgroundColor: colors.poll_fill,
	},
	optionContent: {
		paddingHorizontal: spacing.lg,
		paddingVertical: spacing.md,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: spacing.xs,
	},
	iconTextContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		gap: spacing.xs,
	},
	titleStyle: {
		flex: 1,
		width: 1,
	},
	optionTrigger: {
		...StyleSheet.absoluteFillObject,
		borderRadius: radius.full,
		borderWidth: 2,
		borderColor: 'transparent',
	},
	optionTriggerHovered: {
		borderColor: colors.poll_border_hover,
	},
	optionTriggerSelected: {
		borderColor: colors.poll_border_hover,
	},
}));

export default OptionsButton;
